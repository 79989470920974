import * as React from "react";
// import { Link } from "gatsby";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles.css";

const Gracias = () => {
  /* const Gracias = ({ location }) => { */
  /* var nombre;
  if (!location.state) {
    nombre = "";
  } else {
    nombre = location.state.name;
  } */

  return (
    <>
      <Helmet>
        <title>Gracias</title>
        <meta
          name="keywords"
          content="al cubo, arquitectos, ecuador, cuenca, portafolio"
        />
        <meta
          name="description"
          content="Estudio de Arquitectura y Construcción sostenible con 18 años de experiencia liderado por los arquitectos Apolo Gavilánez y Valeria Bustos"
        />
        <meta property="og:title" content="Al Cubo Arquitectura" />
        <meta property="og:url" content="https://www.alcuboarquitectura.com/" />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content="Estudio de Arquitectura y Construcción sostenible con 18 años de experiencia liderado por los arquitectos Apolo Gavilánez y Valeria Bustos"
        />
        <meta
          property="og:image"
          content="https://alcuboarquitectura.com/alcubo-banner.jpg"
        />
        <meta property="og:image:type" content="image/jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="900" />
      </Helmet>
      <div className="thanks-cont p-3 mx-auto">
        <Container className="bg-white">
          <Row>
            <Col md={12} className="mx-auto px-0">
              <StaticImage
                src="../images/alcubo-banner.jpg"
                alt="Bambú Sierra banner"
                layout="fullWidth"
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} className="text-center pt-2 mt-4">
              <h1 className="thanks-title pb-3">
                ¡Hola!
                {/* {nombre ? <span>¡Hola {nombre}!</span> : <span>¡Gracias!</span>} */}
                {/* ¡Hola <span>{nombre}!</span> */}
              </h1>
              <p className="thanks-text">Gracias por contactarnos</p>
              <p className="thanks-text">
                Nos pondremos en contacto contigo a la mayor brevedad posible.
              </p>
              <p className="thanks-text mb-3 mb-md-0">
                Si necesitas una asesoría inmediata nos puedes contactar por
                WhatsApp dando click en el botón de abajo.
              </p>
            </Col>
          </Row>
          <Row className="pb-4">
            <Col md={12} className="text-center pb-2">
              <a href="https://wa.me/593999241435">
                <Button variant="success" className="thanks-btn mt-0 mt-md-3">
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    className="whatsapp-icon"
                  />
                  <span className="ms-1">Unirme al chat</span>
                </Button>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Gracias;
